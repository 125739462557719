<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
      <AbstractTable
        :fields="fields"
        :items="pathologies"
        :actions="actions"
        title="Patologías"
        :labelItemPerPage="'Patologías por página:'"
        :addBtn="{ enable: $store.state.isAdmin, text: 'Añadir nueva patología', event: 'addElement'}"
        @addElement="addPathology"
        @showDeleteModal="handleModal"
        :noResults="'No se han encontrado patologías'"
      />
      
      <DeleteModal :modalContent="modalContent" @delete="deletePathologyData(pathologId)" :showModal="deleteModal" @closeModal="closeModal" />

      </CCol>
    </CRow>
  </div>
</template>

<script>
import "regenerator-runtime/runtime";
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getPathologiesData, deletePathology } from '@/services/pathologies';
import { limitCharacters, generateField } from '@/utils/utils';
export default {
  name: "PatologiaTable",
  components: { AbstractTable, DeleteModal, Loader },
  data(){
    return{
      loading: true,
      deleteModal: false,
      pathology: {},
      pathologies: [],
      pathologId: 0,
      pathologyName: '',
      fields: [
        generateField("pathology_name", "Nombre de la patología", {styleData: { textAlign: 'center'}}),
        generateField("pathology_description", "Descripción de la patología"),
        generateField("actions", 'Acciones', { show: this.$store.state.isAdmin, sorter: false, filter: false })
      ],
      actions: [
        {
          key: 'delete',
          label: 'Eliminar',
          isAdmin: !this.$store.state.isAdmin,
          event: 'showDeleteModal',
          classButton: 'btn btn-delete btn-sm btn-pill'
        },
      ],
      modalContent: {
            title: 'Eliminar patología',
            textBody: '',
            buttons: [
                {
                  textButton: 'Cerrar',
                  color: 'secondary',
                  event: 'closeModal'
                },
                {
                  textButton: 'Aceptar',
                  color: 'danger',
                  event: 'delete'
                },
            ]
      }
    }
  },
  created() {
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getPathologies();
    },
     /**
     * Función en la que se obtienen las patologías y que también
     * llama el metodo receiveData para cargarlas.
     * 
     * @returns {object[]} Las patologías obtenidas.
     */
    getPathologies() {
      getPathologiesData()
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
        .then((result) => {
        this.pathologies = result
        this.pathologies.forEach((pathology) => {
          pathology.id_pathology = pathology.id;
          pathology.pathology_name = limitCharacters(pathology.name, 20);
          pathology.pathology_description = pathology.description;
        })
        this.loading = false;
      });
    },
    /**
     * Este método se ejecuta cuando el usuario pulsa el botón Añadir nueva patología y redirige a la vista del formulario para poder crear una.
     */
    addPathology() {
      this.$router.push(`/pathology/create/`);
    },
     /**
      * Método para controlar lo que se muestra en el Modal de Eliminar patología.
      */
    handleModal(item){
      this.pathologId = item.id_pathology;
      this.modalContent.textBody = `¿Está seguro de que desea eliminar la patología: <strong>"${item.pathology_name}</strong>"? Esta acción no se puede deshacer.`,
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará la patología, mandando por la URL el id
     * 
     * @param {number} id - El id de la patología a eliminar
     */
     deletePathologyData(id) {
      deletePathology(id)
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
        .then(() => {
          this.getPathologies();
          this.deleteModal = false;
      });
     },
     /**
      * Método para cerrar el modal.
      */
     closeModal(){
        this.deleteModal = false;
    }
  },
};
</script>